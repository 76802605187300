import React, { useEffect, useState } from "react";
import DeptButton from "./DeptButton";
import { FaBell } from "react-icons/fa";
import { FcBusinessman } from "react-icons/fc";
import Badge from "react-bootstrap/Badge";
import "./NavComponents.css";
import { Dropdown, Modal, Form, Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { logoutUser } from "../../redux/AuthSlice";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import API from "../../API/ServiceLayer";

const departments = [
  {
    dept_name: "TECH",
    hover_text: "Technology Department",
    path: "technology-department",
  },
  {
    dept_name: "OPS",
    hover_text: "Operations",
    path: "operations",
  },
  {
    dept_name: "SALES",
    hover_text: "Sales Department",
    path: "sales-department",
  },
  {
    dept_name: "PROJ",
    hover_text: "Projects Department",
    path: "projects-department",
  },
  {
    dept_name: "DVC",
    hover_text: "Deal Validation Committee",
    path: "deal-validation-committee",
  },
  {
    dept_name: "CST",
    hover_text: "Customer Support Team",
    path: "customer-support-team",
  },
  {
    dept_name: "ACC",
    hover_text: "Accounts Department",
    path: "accounts-department",
  },
  {
    dept_name: "HR",
    hover_text: "Human Resources",
    path: "human-resources",
  },
];

const Navbar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [userName, setUserName] = useState("");
  const [userRole, setUserRole] = useState("");

  const [isMobile, setIsMobile] = useState(false);

  //password
  const [show, setShow] = useState(false);
  const [password1, setPassword1] = useState("");
  const [password2, setPassword2] = useState("");
  const [passErr1, setPassErr1] = useState("");
  const [passErr2, setPassErr2] = useState("");
  const [matchPassword, setMatchPassword] = useState("");
  const [passwordIsTypePassword, setPasswordIsTypePassword] = useState(true);
  const [cPasswordIsTypePassword, setCPasswordIsTypePassword] = useState(true);

  const handleShow = () => setShow(true);
  const handleClose = () => {
    setShow(false);
    setPassErr1("");
    setPassErr2("");
    setPassword1("");
    setPassword2("");
    setMatchPassword("");
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    const userId = localStorage.getItem("id");
    const roleId = localStorage.getItem("role_id");

    const data = {
      password: password1,
      user_id: userId,
      role_id: roleId,
    };

    try {
      const response = await API.postMethod(
        "api/password_update",
        "post",
        data
      );

      // Check if the response is OK before parsing JSON
      if (!response.ok) {
        throw new Error(
          `Server error: ${response.status} - ${response.statusText}`
        );
      }

      let res;
      try {
        res = await response.json();
      } catch (jsonError) {
        throw new Error("Failed to parse response as JSON. Please try again.");
      }

      if (res.status === "success") {
        setShow(false);
        Swal.fire({
          icon: "success",
          title: "Success",
          text: res?.data?.message || "Password Updated Successfully",
          position: "top-right",
          toast: true,
          timer: 3000,
          background: "rgb(255, 165, 0)",
          color: "#fff",
          timerProgressBar: true,
          showConfirmButton: false,
        });
      } else {
        throw new Error(res?.message || "Error updating password.");
      }
    } catch (error) {
      console.error("Error:", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text:
          error.message || "Error updating Password data. Please try again.",
        position: "top-right",
        toast: true,
        timer: 3000,
        background: "#dc3545",
        color: "#fff",
        timerProgressBar: true,
        showConfirmButton: false,
      });
    }
  };

  //end of password

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 700);
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  //fetching the username and role
  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));

    if (user && user.data) {
      setUserName(user.data.name);
      const role = user.data.roles[0]?.role_name || "User";
      setUserRole(role);
    }
  }, []);

  const handleLogout = async () => {
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "You will be logged out!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, log me out!",
    });

    if (result.isConfirmed) {
      await dispatch(logoutUser());
      Swal.fire({
        title: "Logged out!",
        text: "You have been logged out successfully.",
        icon: "success",
        timer: 2000,
        showConfirmButton: false,
      });
      navigate("/");
    }
  };

  return (
    <div
      className="header_container"
      style={{
        boxShadow: "rgba(0, 0, 0, 0.04) 0px 3px 5px",
      }}>
      {/* -- heading section -- */}
      <div>{isMobile ? <h6>MIKRO CENTRAL</h6> : <h4>MIKRO CENTRAL</h4>}</div>
      {/* -- department section -- */}
      <div
        className={
          isMobile ? "navbar_departmentsMobile" : "navbar_departments"
        }>
        {departments?.map((item) => (
          <DeptButton
            key={item?.dept_name}
            className="me-2"
            path={item?.path}
            name={item?.dept_name}
            hover_text={item?.hover_text}
          />
        ))}
      </div>
      {/* -- user and notification section -- */}
      <div className="nav-end d-flex align-items-center justify-content-end">
        <span className="bell position-relative me-3">
          <FaBell className="fs-2" size={isMobile ? 20 : 30} />
          <Badge pill bg="danger" className="bell-badge position-absolute">
            9
          </Badge>
        </span>

        <Dropdown>
          <Dropdown.Toggle
            as="span"
            id="dropdown-basic"
            className="user d-flex flex-column align-items-center"
            style={{ cursor: "pointer" }}>
            <FcBusinessman className="fs-1" />
            <p className="name m-0 fw-bold">{userName}</p>
            <h5 className="role m-0 fw-bold">{userRole}</h5>
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item onClick={handleShow}>Change Password</Dropdown.Item>
            <Modal
              backdrop="static"
              show={show}
              onHide={handleClose}
              dialogClassName="modal-90w"
              aria-labelledby="example-custom-modal-styling-title">
              <Modal.Header closeButton>
                <Modal.Title id="example-custom-modal-styling-title">
                  Change Password
                </Modal.Title>
              </Modal.Header>

              <Modal.Body>
                <form onSubmit={handleFormSubmit}>
                  <Form.Group className="position-relative mb-4">
                    <label>
                      New Password <span className="text-danger">*</span>
                    </label>
                    <Form.Control
                      type={passwordIsTypePassword ? "password" : "text"}
                      minLength={6}
                      maxLength={55}
                      value={password1}
                      onChange={(e) => setPassword1(e.target.value)}
                      placeholder="Enter New Password"
                      required
                      size="sm"
                    />
                    <button
                      type="button"
                      className="password-toggle-btn"
                      style={{
                        position: "absolute",
                        top: "72%",
                        right: "0px",
                        transform: "translateY(-50%)",
                        border: "none",
                        background: "transparent",
                        borderRadius: "25%",
                        padding: "4px",
                      }}
                      onClick={() =>
                        setPasswordIsTypePassword(!passwordIsTypePassword)
                      }>
                      {passwordIsTypePassword ? (
                        <AiOutlineEyeInvisible size="1.5em" color="#607d8b" />
                      ) : (
                        <AiOutlineEye size="1.5em" color="#607d8b" />
                      )}
                    </button>
                    {passErr1 && (
                      <span className="text-danger text-small">{passErr1}</span>
                    )}
                  </Form.Group>

                  <Form.Group className="position-relative mb-4">
                    <label>
                      Confirm New Password{" "}
                      <span className="text-danger">*</span>
                    </label>
                    <Form.Control
                      type={cPasswordIsTypePassword ? "password" : "text"}
                      minLength={6}
                      maxLength={55}
                      value={password2}
                      onChange={(e) => setPassword2(e.target.value)}
                      placeholder="Confirm Password"
                      required
                      size="sm"
                    />
                    <button
                      type="button"
                      className="password-toggle-btn"
                      style={{
                        position: "absolute",
                        top: "72%",
                        right: "0px",
                        transform: "translateY(-50%)",
                        border: "none",
                        background: "transparent",
                        borderRadius: "25%",
                        padding: "4px",
                      }}
                      onClick={() =>
                        setCPasswordIsTypePassword(!cPasswordIsTypePassword)
                      }>
                      {cPasswordIsTypePassword ? (
                        <AiOutlineEyeInvisible size="1.5em" color="#607d8b" />
                      ) : (
                        <AiOutlineEye size="1.5em" color="#fff" />
                      )}
                    </button>
                    {passErr2 && (
                      <span className="text-danger text-small">{passErr2}</span>
                    )}
                    {matchPassword && (
                      <span className="text-danger text-small">
                        {matchPassword}
                      </span>
                    )}
                  </Form.Group>

                  <div className="d-flex flex-wrap justify-content-end gap-2">
                    <Button
                      onClick={handleClose}
                      className="rounded-0"
                      style={{
                        background: "linear-gradient(45deg, #ffcc00, #ff9933)",
                        border: "none",
                        outline: "none",
                        padding: "10px 30px",
                        transition:
                          "box-shadow 0.3s ease-in-out, transform 0.2s ease-in-out",
                        boxShadow:
                          "inset 0 4px 6px rgba(255, 224, 47, 0.5), 0 6px 12px rgba(0, 0, 0, 0.2)",
                      }}>
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      variant="outline-light"
                      className="rounded-0"
                      style={{
                        background: "linear-gradient(45deg, #32cd32, #228b22)",
                        border: "none",
                        outline: "none",
                        padding: "10px 30px",
                        transition:
                          "box-shadow 0.3s ease-in-out, transform 0.2s ease-in-out",
                        boxShadow:
                          "inset 0 4px 6px rgba(173, 255, 47, 0.5), 0 6px 12px rgba(0, 0, 0, 0.2)",
                      }}>
                      Update
                    </Button>
                  </div>
                </form>
              </Modal.Body>
            </Modal>

            <Dropdown.Item onClick={handleLogout}>Logout</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </div>
  );
};

export default Navbar;
