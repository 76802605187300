import axios from "axios";

const instance = axios.create({
  //postman chack
  baseURL: process.env.REACT_APP_API_URL,
});

// instance.interceptors.response.use((res)=>res, err=>{
//   console.log(err.response)
//   if(err.response.status === 401){
//     window.location = "/login"
//     localStorage.clear();
//   }
// })

// REACT_APP_API_URL = https://dev.berighthere.com/
// REACT_APP_IMAGE_URL= https://brhfiles.s3.ap-south-1.amazonaws.com/

// API.getData(``)
export default instance;
