import React, { useState, useEffect, useCallback } from "react";
import { Button, Form, Container, Row, Col } from "react-bootstrap";
import "./ProcessForm.css";
import Select from "react-select";
import URL from "../../../API/Api";

const ProcessForm = ({
  formData,
  setFormData,
  handleSubmit,
  buttonName,
  handleClose,
  errors,
}) => {
  const [departments, setDepartments] = useState([]);
  const [organizations, setOrganizations] = useState([]); // State for organizations
  const [selectOrgValue, setselectOrgValue] = useState();
  console.log(selectOrgValue, "selectOrgValue");
  const [processsField, setprocesssField] = useState([]);
  const [processNumberFlow, setprocessNumberFlow] = useState();

  // Using useCallback to memoize the functions
  const fetchDepartments = useCallback(async () => {
    try {
      const response = await URL.get("user-management/api/v1/orgdept");
      const departmentData = response.data.data;
      console.log("departmentData", departmentData);
      setDepartments(departmentData);
    } catch (error) {
      console.error("Error fetching departments:", error);
    }
  }, []);

  const fetchOrganizations = useCallback(async () => {
    try {
      const response = await URL.get("api/org_process");
      const organizationData = response.data.data;
      // console.log("organizationDatasq", organizationData);
      setOrganizations(organizationData);
    } catch (error) {
      // console.error("Error fetching organizations:", error);
    }
  }, []);

  useEffect(() => {
    fetchDepartments();
    fetchOrganizations();
  }, [fetchOrganizations, fetchDepartments]); // Now these dependencies are memoized functions

  const GetprocessFlowNumber = (d) => {
    const selectedOrgName = organizations.find(
      (orgz) => orgz.org_id == parseInt(d)
    );

    console.log(selectedOrgName, "orgname");

    if (selectedOrgName) {
      setprocesssField(selectedOrgName.processes);
      if (selectedOrgName?.processes?.length === 0) {
        setprocessNumberFlow(1);
        // console.log("length is zero");
      } else {
        const lastObject =
          selectedOrgName?.processes[selectedOrgName?.processes?.length - 1];

        const gettheFlowOrder = lastObject.process_flow_order;
        // console.log(gettheFlowOrder, "hhhhhhh");
        // console.log(lastObject.process_flow_order, "getj");

        const AddCountFlowOrder = gettheFlowOrder + 1;
        // console.log("length is present");

        setprocessNumberFlow(parseInt(AddCountFlowOrder));
      }
    } else {
      setprocesssField([]);
      setprocessNumberFlow(1);
      // console.log("onee length");
    }
  };
  // console.log(processNumberFlow, "ppppflow");
  // Handle input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name == "orgName") {
      console.log(value, "mvalue");
      setselectOrgValue(value);
      GetprocessFlowNumber(value);
    }
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
      process_flow_order: processNumberFlow,
    }));
  };

  // Handle changes for the multi-select dropdown
  const handleDepartmentChange = (selectedOptions) => {
    // Extract values from selectedOptions and update the formData
    const departmentIds = selectedOptions
      ? selectedOptions.map((option) => option.value)
      : [];
    setFormData((prevData) => ({
      ...prevData,
      department: departmentIds, // Store selected department IDs as array
    }));
  };

  return (
    <Container className="process-form-container">
      <h2 className="text-center mb-4">Process</h2>
      <Form onSubmit={handleSubmit}>
        <Row>
          {/* Organization Dropdown */}
          <Col md={4} style={{ padding: "10px" }}>
            <Form.Group controlId="orgName">
              <Form.Label>
                Organization{" "}
                <span className="text-danger" style={{ fontSize: "0.85em" }}>
                  *
                </span>
              </Form.Label>
              <Form.Control
                as="select"
                name="orgName"
                value={formData.orgName}
                onChange={handleChange}
                className={`select-placeholder ${
                  formData.organization === "" ? "placeholder" : ""
                }`}>
                <option value="" style={{ color: "#525658" }}>
                  Select Organization
                </option>
                {/* Map through organizations */}
                {organizations.map((org, index) => (
                  <option key={index} value={org.org_id}>
                    {org.org_name}
                  </option>
                ))}
              </Form.Control>
              {errors.orgName && (
                <span className="text-danger" style={{ fontSize: "0.85em" }}>
                  {errors.orgName}
                </span>
              )}
            </Form.Group>
          </Col>

          {/* Process Name */}
          <Col md={4} style={{ padding: "10px" }}>
            <Form.Group controlId="processName">
              <Form.Label>
                Process Name{" "}
                <span className="text-danger" style={{ fontSize: "0.85em" }}>
                  *
                </span>
              </Form.Label>
              <Form.Control
                type="text"
                name="processName"
                value={formData.processName}
                placeholder="Enter process name"
                onChange={handleChange}
              />
              {errors.processName && (
                <span className="text-danger" style={{ fontSize: "0.85em" }}>
                  {errors.processName}
                </span>
              )}
            </Form.Group>
          </Col>

          {/* Process Flow Order */}
          <Col md={4} style={{ padding: "10px" }}>
            <Form.Group controlId="processFlowOrder">
              <Form.Label>Process Flow Order</Form.Label>
              <Form.Control
                type="number"
                name="processFlowOrder"
                placeholder="Enter process flow order"
                value={processNumberFlow}
                onChange={handleChange}
                disabled
              />
            </Form.Group>
          </Col>

          {/* Department Dropdown */}

          <Form.Group controlId="department">
            <Form.Label>
              Department{" "}
              <span className="text-danger" style={{ fontSize: "0.85em" }}>
                *
              </span>
            </Form.Label>
            <div className="department-select-wrapper">
              <Select
                isMulti
                name="department"
                value={departments
                  .filter((dept) => formData.department.includes(dept.id))
                  .map((dept) => ({
                    label: dept.department_name,
                    value: dept.id,
                  }))}
                options={departments.map((dept) => ({
                  label: dept.department_name,
                  value: dept.id,
                }))}
                onChange={handleDepartmentChange}
                classNamePrefix="select"
                placeholder="Select Department"
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    background: "#e7e7e7",
                    border: "none",
                    boxShadow: state.isFocused
                      ? "0 0 0 2px rgba(0, 123, 255, 0.25)"
                      : null,
                    borderRadius: "34px", // Adjusted to match your existing border-radius style
                    padding: "2px", // Optional padding
                  }),
                  menu: (baseStyles) => ({
                    ...baseStyles,
                    borderRadius: "34px", // Optional to match your other form elements
                    zIndex: 100,
                  }),
                  multiValue: (baseStyles) => ({
                    ...baseStyles,
                    backgroundColor: "#e9ecef",
                    borderRadius: "20px",
                  }),
                  multiValueLabel: (baseStyles) => ({
                    ...baseStyles,
                    color: "#495057",
                  }),
                  multiValueRemove: (baseStyles) => ({
                    ...baseStyles,
                    color: "#495057",
                    "&:hover": {
                      backgroundColor: "#d9534f",
                      color: "#fff",
                    },
                  }),
                }}
              />
              {errors.department && (
                <span className="text-danger" style={{ fontSize: "0.85em" }}>
                  {errors.department}
                </span>
              )}
            </div>
          </Form.Group>
        </Row>

        {/* Action Buttons */}
        <div className="form-actions mb-4">
          <Button
            variant="outline-light"
            type="reset"
            className="cancel-btn"
            onClick={handleClose}>
            Cancel Process
          </Button>
          <Button variant="outline-light" type="submit" className="create-btn">
            {buttonName}
          </Button>
        </div>
      </Form>
    </Container>
  );
};

export default ProcessForm;
