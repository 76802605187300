import { configureStore } from '@reduxjs/toolkit';
import authReducer from './AuthSlice';
import SideBarReducer from './SideBarSlice';

const store = configureStore({
  reducer: {
    auth: authReducer,
    sidebar: SideBarReducer,
  },
});

export default store;
