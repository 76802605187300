import React, { useEffect, useState } from "react";
import API from "../../API/ServiceLayer";
import Form from "react-bootstrap/Form";
import { Link } from "react-router-dom";
import moment from "moment";
// import Skeleton from "@mui/material/Skeleton";
import { Container, Pagination, Table, Button } from "react-bootstrap";
import { Modal } from "react-bootstrap";
import Swal from "sweetalert2";
import BounceLoader from "react-spinners/BounceLoader";

const Staff = (props) => {
  const [staffList, setStaffList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage] = useState(10);
  const [loader, setLoader] = useState(false);
  const [deptname, setdeptName] = useState([]);
  const [accessDataSubmenu, setAccessDataSubmenu] = useState([]);
  const [accessDataFunctionality, setAccessDataFunctionality] = useState([]);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedStaffId, setSelectedStaffId] = useState(null);

  const handleShowAddModal = () => setShowAddModal(true);
  const handleCloseAddModal = () => setShowAddModal(false);

  const handleShowEditModal = (id) => {
    setSelectedStaffId(id);
    setShowEditModal(true);
  };
  const handleCloseEditModal = () => {
    setSelectedStaffId(null);
    setShowEditModal(false);
  };

  useEffect(() => {
    getStaffDetails();
    // const data = props.history.location.state;
    // console.log(data, "data");
    // const userData = JSON.parse(localStorage.getItem("user"));

    // const payload = {
    //   module_id: 1,
    //   menu_id: data?.menu_id,
    //   submenu_id: data?.id,
    //   organization_department_id:
    //     userData?.roles[0]?.role_name === "Super Admin"
    //       ? 24
    //       : userData?.user_org_depts[0]?.org_dept_id,
    //   organization_department_role_id:
    //     userData?.roles[0]?.role_name === "Super Admin"
    //       ? 34
    //       : userData?.user_org_depts[0]?.org_dept_role_id,
    // };
    // console.log(payload, "payload");

    // API.getDatabyBody("user-management/api/v1/menu-sub-access", payload).then(
    //   (res) => {
    //     if (res.status) {
    //       console.log(res.data, "res");
    //       setAccessDataSubmenu(res.data.data.find((i) => !i.functionality));
    //       setAccessDataFunctionality(
    //         res.data.data.find((i) => i.functionality_id)
    //       );
    //     }
    //   }
    // );
  }, []);

  const getStaffDetails = async () => {
    let data = [];
    await API.getData("user-management/api/v1/user")
      .then((res) => res.json())
      .then((result) => {
        if (result.data) {
          console.log("staff", result.data);
          let finalData = result.data
            .sort((a, b) => b.id - a.id)
            .filter((ele) =>
              ele.roles.some((element) => element.pivot.role_id == 10)
            );

          setStaffList(finalData);

          //  console.log("apidata2", finalData);
          finalData.forEach((user) => {
            user.user_org_depts.forEach((department) => {
              if (department.organization_department.status === 1) {
                // console.log(
                //   department.organization_department.department_name,
                //   "methods mass"
                // );

                data.push(department.organization_department.department_name);
                // console.log(data, "depppppppt");
              }
            });
          });
          setdeptName(data);
          // console.log( finalData.map((qdata)=>(
          //   qdata
          // )) , "mass data form" )
          // console.log(finalData.map((user_org_depts)=>{
          //   user_org_depts.
          // }) , " USer variation data ")
          setLoader(true);
          setStaffList(finalData);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        setLoader(true);
      });
  };

  //handle enable/disenable function
  function handleStatusUpdate(e, value) {
    if (e.target.name == "status" && e.target.checked == true) {
      value.status = 1;
    } else if (e.target.name == "status" && e.target.checked == false) {
      value.status = 0;
    }
    let data = {
      status: value.status,
    };
    API.postMethod("user-management/api/v1/user/" + value.id, "put", data)
      .then((res) => res.json())
      .then((result) => {
        console.log("API-USER_UPDATE", result);
        if (result.status == true) {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: result.message,
            position: "top-right",
            toast: true,
            timer: 3000,
            background: "rgb(255, 165, 0)",
            color: "#fff",
            showConfirmButton: true,
          }).then(function () {
            Swal.close();
            window.location.href = "/user-management/organization-staff";
          });
        } else {
          Swal.fire({
            icon: "warning",
            title: "Error",
            text: result.message,
            position: "top-right",
            toast: true,
            timer: 3000,
            background: "rgb(255, 165, 0)",
            color: "#fff",
            showConfirmButton: true,
          }).then(function () {
            Swal.close();
          });
        }
      })
      .catch((e) => {
        console.log("error", e);
      });
  }

  // Calculate pagination details
  const indexOfLastStaff = currentPage * rowsPerPage;
  const indexOfFirstStaff = indexOfLastStaff - rowsPerPage;
  const currentStaff = staffList.slice(indexOfFirstStaff, indexOfLastStaff);

  const totalPages = Math.ceil(staffList.length / rowsPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  //   const handleStatusUpdate = (event, rowData) => {
  //     // Your status update logic here
  //     console.log("Status updated for:", rowData);
  //   };

  return (
    <div>
      {/* page title */}
      {/* page body */}
      <div className="row">
        <div className="ml-auto mr-4">
          <Link
            className="btn btn-sm mb-2 mt-2"
            to="/organization-staff-add"
            style={{
              color: "white",
              background: "linear-gradient(45deg, rgb(255, 152, 0), #ffc107)",
              boxShadow: "rgba(0, 0, 0, 0.2) 0px 4px 15px",
              border: "none",
              float: "right",
              borderradius: "8px",
              padding: "10px 15px",
              flexDirection: "row",
              alignItems: "center",
            }}>
            + Organization Staff
          </Link>
          {/* <Button
            className="btn btn-sm mb-2 mt-2 btn-primary text-uppercase"
            onClick={handleShowAddModal}>
            Add New Organization Staff
          </Button> */}

          {/* Add New Organization Staff Modal */}
          {/* <Modal show={showAddModal} onHide={handleCloseAddModal} size="lg">
            <Modal.Header closeButton>
              <Modal.Title>Add New Organization Staff</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <AddOrganizationStaffForm onClose={handleCloseAddModal} />
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleCloseAddModal}>
                Close
              </Button>
            </Modal.Footer>
          </Modal> */}
        </div>
      </div>
      {loader == true ? (
        <>
          {staffList ? (
            <>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Email ID</th>
                    <th>Contact Number</th>
                    <th>Department</th>
                    <th>Onboarded By</th>
                    <th>Onboarded Date & Time</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {currentStaff.map((rowData) => (
                    <tr key={rowData.id}>
                      <td>
                        {/* <Link
                          to={`/organization-staff-edit/${rowData.id}`}
                          state={rowData.id}>
                          {rowData.name}
                        </Link> */}

                        <Link to={`/organization-staff-edit/${rowData.id}`}>
                          {rowData.name}
                        </Link>
                        {/* <Button
                          variant="link"
                          className="p-0"
                          onClick={() => handleShowEditModal(rowData.id)}>
                          {rowData.name}
                        </Button>

                        
                        <Modal
                          show={showEditModal}
                          onHide={handleCloseEditModal}
                          size="lg">
                          <Modal.Header closeButton>
                            <Modal.Title>Edit Organization Staff</Modal.Title>
                          </Modal.Header>
                          <Modal.Body>
                            {selectedStaffId && (
                              <EditOrganizationStaff
                                staffId={selectedStaffId}
                                onClose={handleCloseEditModal}
                              />
                            )}
                          </Modal.Body>
                          <Modal.Footer>
                            <Button
                              variant="secondary"
                              onClick={handleCloseEditModal}>
                              Close
                            </Button>
                          </Modal.Footer>
                        </Modal>{" "}
                        */}
                      </td>
                      <td>{rowData.email ? rowData.email : " -"}</td>
                      <td>
                        {rowData.mobile_no
                          ? rowData.mobile_no
                          : rowData?.contact_number}
                      </td>
                      <td>
                        {rowData.user_org_depts &&
                        rowData.user_org_depts.length > 0 ? (
                          <div>
                            {rowData.user_org_depts.map((d) =>
                              d && d["organization_department"].status === 1 ? (
                                <p key={d.id} style={{ fontSize: "12px" }}>
                                  {d["organization_department"].department_name}
                                </p>
                              ) : (
                                <p key={d.id}> -- </p>
                              )
                            )}
                          </div>
                        ) : (
                          " - "
                        )}
                      </td>
                      <td>
                        {rowData.on_boarding_mode ? (
                          <>
                            {rowData.on_boarding_mode} through{" "}
                            {rowData.register_from}
                          </>
                        ) : (
                          " - "
                        )}
                      </td>
                      <td>
                        {rowData.created_at ? (
                          <>
                            {moment(rowData.created_at).format(`DD-MMM-YYYY`)}
                            <br />
                            {moment(rowData.created_at).format(`h:mm A`)}
                          </>
                        ) : (
                          "-"
                        )}
                      </td>
                      <td>
                        <Form.Check
                          name="status"
                          type="switch"
                          onChange={(e) => handleStatusUpdate(e, rowData)}
                          defaultChecked={rowData.status === 1}
                          id={`${rowData.name}-${rowData.id}`}
                          label=""
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>

              {/* Pagination */}
              <Pagination>
                {[...Array(totalPages)].map((_, index) => (
                  <Pagination.Item
                    key={index}
                    active={index + 1 === currentPage}
                    onClick={() => handlePageChange(index + 1)}>
                    {index + 1}
                  </Pagination.Item>
                ))}
              </Pagination>
            </>
          ) : (
            <>
              {/* <LoadingSkeleton /> */}
              {/* Loading */}
              <Container
                style={{
                  height: "60vh",
                }}
                className=" d-flex align-items-center justify-content-center ">
                <BounceLoader size={100} color="orange" />
              </Container>
            </>
          )}
        </>
      ) : (
        <>
          <Container
            style={{
              height: "60vh",
            }}
            className=" d-flex align-items-center justify-content-center ">
            <BounceLoader size={100} color="orange" />
          </Container>
        </>
      )}
    </div>
  );
};

export default Staff;
