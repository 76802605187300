import {
  FaHome,
  FaClock,
  FaFileAlt,
  FaSignOutAlt,
  FaTasks,
  FaRegFileAlt,
  FaCalendarAlt,
  FaCogs,
  FaBuilding,
  FaSitemap,
} from "react-icons/fa";
import { BiSolidDashboard } from "react-icons/bi";

export const SidebarData = [
  {
    name: "Dashboard",
    path: "/dashboard",
    icon: BiSolidDashboard,
  },
  {
    name: "Masters",
    icon: FaCogs,
    submenu: [
      { name: "Tree", path: "/master/tree", icon: FaSitemap },
      { name: "Organization", path: "/master/Org", icon: FaClock },
      { name: "Process", path: "/master/process", icon: FaCalendarAlt },
      { name: "Task", path: "/master/task", icon: FaTasks },
      { name: "Department", path: "/master/organization", icon: FaBuilding },
    ],
  },

  {
    name: "Utilities",
    icon: FaRegFileAlt,
    submenu: [
      { name: "Leave", path: "/utilities/leave", icon: FaClock },
      { name: "Claims", path: "/utilities/claims", icon: FaFileAlt },
      { name: "Events", path: "/utilities/events", icon: FaCalendarAlt },
    ],
  },
  {
    name: "SLA",
    path: "/sla",
    icon: FaFileAlt,
  },
];
