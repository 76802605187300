import React, { useEffect, useState } from "react";
import { SidebarData } from "./SidebarData";
import MGlogo from "./../../Assets/Images/MGLogo.webp";
import { SidebarMenuItem } from "./SidebarMenuItem";
import { GiHamburgerMenu } from "react-icons/gi";
import "./SidebarComponents.css";
import { useDispatch, useSelector } from "react-redux";
import { selectIsSidebarOpen, toggleSidebar } from "../../redux/SideBarSlice";
import { MobileSideMenuItem } from "./MobileSideMenuItem";

const Sidebar = () => {
  const [activeItem, setActiveItem] = useState("/dashboard"); // Set initial active path

  const dispatch = useDispatch();
  const isOpen = useSelector(selectIsSidebarOpen);

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 700);
    };

    handleResize(); // Check initial size
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize); // Cleanup on unmount
  }, []);

  const handleHamburgerMenu = () => {
    dispatch(toggleSidebar());
  };

  console.log(isOpen, "myopen");
  return (
    <div>
      <div className="sidebar_image_container">
        {isOpen && (
          <img
            className="cursor_pointer"
            src={MGlogo}
            alt="MG Logo"
            height="60"
          />
        )}
        {isMobile ? (
          <img
            className="cursor_pointer"
            src={MGlogo}
            alt="MG Logo"
            height="25"
          />
        ) : (
          <GiHamburgerMenu
            onClick={handleHamburgerMenu}
            className="cursor_pointer"
            size={30}
            color="#fff"
          />
        )}
      </div>
      {isMobile ? (
        <div className="menuItemContainer d-flex flex-column justify-content-center">
          {SidebarData.map((item, index) => (
            <MobileSideMenuItem
              key={index}
              icon={item.icon}
              path={item.path}
              name={item.name}
              submenu={item.submenu}
              isActive={activeItem === item.path}
              activeItem={activeItem}
              setActiveItem={setActiveItem}
            />
          ))}
        </div>
      ) : (
        <div>
          {isOpen ? (
            <div className="menuItemContainer d-flex flex-column justify-content-center">
              {SidebarData.map((item, index) => (
                <SidebarMenuItem
                  key={index}
                  icon={item.icon}
                  path={item.path}
                  name={item.name}
                  submenu={item.submenu}
                  isActive={activeItem === item.path}
                  activeItem={activeItem}
                  setActiveItem={setActiveItem}
                />
              ))}
            </div>
          ) : (
            <div className="menuItemContainer d-flex flex-column justify-content-center">
              {SidebarData.map((item, index) => (
                <MobileSideMenuItem
                  key={index}
                  icon={item.icon}
                  path={item.path}
                  name={item.name}
                  submenu={item.submenu}
                  isActive={activeItem === item.path}
                  activeItem={activeItem}
                  setActiveItem={setActiveItem}
                />
              ))}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Sidebar;
