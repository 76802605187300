import React, { Component } from "react";
import axios from "axios";

//api

//const API_URL = "https://uatnew.berighthere.com/";

const API_URL = process.env.REACT_APP_API_URL;

// const API_URL = "http://13.233.76.246/";
let user = JSON.parse(localStorage.getItem("user"));

let token_type = localStorage.getItem("token_type")
  ? localStorage.getItem("token_type")
  : "";
let token = user?.data.token ? user?.data.token : "";

const ServiceLayer = {
  async postMethod(URL, Method, Data) {
    let url = API_URL + URL;

    return await fetch(url, {
      method: Method,
      headers: {
        "Content-Type": "application/json",
        // "Access-Control-Allow-Origin": "*",
        Authorization: token_type + " " + token,
      },
      body: JSON.stringify(Data),
    });
  },

  postaMethod(URL, Method, Data) {
    let url = API_URL + URL;

    return axios.post(url, Data, {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: token_type + " " + token,
      },
    });
  },

  fileupload(URL, Data) {
    let url = API_URL + URL;

    return axios.post(url, Data, {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: token_type + " " + token,
      },
    });
  },

  loginAPI(URL, Data) {
    let url = API_URL + URL;

    return axios.post(url, Data);
  },

  fileputupload(URL, Data) {
    let url = API_URL + URL;

    return axios.put(url, Data, {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: token_type + " " + token,
      },
    });
  },

  editData(URL, Data) {
    let url = API_URL + URL;

    return axios.put(url, Data, {
      headers: {
        Authorization: token_type + " " + token,
      },
    });
  },

  // getData(URL) {
  //   console.log("hi");
  //   console.log("token", token);
  //   let url = API_URL + URL;
  //   return fetch(url, {
  //     headers: {
  //       Authorization: token_type + " " + token,
  //     },
  //   });
  // },

  async getData(URL) {
    console.log("hi");
    let token_type = localStorage.getItem("token_type") || "";
    let user = JSON.parse(localStorage.getItem("user") || "{}");
    let token = user?.data?.token || "";

    let url = API_URL + URL;

    return await fetch(url, {
      headers: {
        Authorization: `${token_type} ${token}`,
      },
    });
  },

  getDatabyBody(URL, data) {
    let url = API_URL + URL;
    console.log(JSON.stringify(data), "datapayload");
    // return fetch(url, {
    //   headers: {
    //     Authorization: token_type + " " + token,
    //   },
    //   body: data,
    // });
    return axios.get(url, {
      headers: {
        Authorization: token_type + " " + token,
      },
      params: data,
    });
  },
  getDataToken(URL) {
    let url = API_URL + URL;
    return fetch(url, {
      headers: {
        Authorization: "2P2mfbGMap5GRtjFhqTP5V85McgPT75BtmkNuRAn",
      },
    });
  },
  deleteData(URL) {
    let url = API_URL + URL;
    return axios.delete(url, {
      headers: {
        Authorization: token_type + " " + token,
      },
    });
  },
};

export default ServiceLayer;
