import React from "react";
import { useNavigate } from "react-router-dom";

const DeptButton = ({ path, name, className, hover_text }) => {
  const navigate = useNavigate();
  return (
    <div
      onClick={() => navigate(`/${path}`)}  
      className={`border  rounded-pill ${className}`}>
      {name}
    </div>
  );
};

export default DeptButton;
