import React, { useCallback, useEffect, useState } from "react";
import { Button, Form, Container, Row, Col } from "react-bootstrap";
import URL from "../../../API/Api";
import "./TaskForm.css";

const TaskForm = ({
  FlowTask,
  Editform,
  editTaskFlowOrder,
  formData,
  setFormData,
  handleSubmit,
  buttonName,
  handleClose,
  errors,
}) => {
  const [processOptions, setProcessOptions] = useState([]);
  const [departmentOptions, setDepartmentOptions] = useState([]); // For departments
  const [roleOptions, setRoleOptions] = useState([]); // For roles
  const [loading, setLoading] = useState(true);
  const [ProcessGetvalue, setProcessGetvalue] = useState();
  const [TaskNumberFlow, setTaskNumberFlow] = useState();
  const [TasksField, setTasksField] = useState([]);
  const [organizations, setOrganizations] = useState([]); // State for organizations

  // const
  // console.log(Editform,"Editform");

  // console.log(FlowTask,"twinle")
  // console.log(processOptions,"processOptions")

  useEffect(() => {
    const fetchProcessesAndDepartments = async () => {
      if (!formData.org_id) return; // Skip if no organization is selected
      setLoading(true);

      try {
        const response = await URL.get(`api/org/${formData.org_id}`);
        if (response.data && response.data.status) {
          const orgData = response.data.data;

          // Format processes for the dropdown
          const formattedProcessOptions = orgData.processes.map((process) => ({
            value: process.process_id,
            label: process.process_name || `Process ${process.process_id}`, // Use a fallback if process name is null
            key: `process-${process.process_id}`,
          }));
          setProcessOptions(formattedProcessOptions);

          // Format departments and their roles for the dropdown
          const formattedDepartments = orgData.departments.map((dept) => ({
            value: dept.department_id,
            label: dept.department_name,
            roles: dept.roles.map((role) => ({
              value: role.role_id,
              label: role.dep_role_name,
            })),
            key: dept.department_id,
          }));
          setDepartmentOptions(formattedDepartments);
        }
      } catch (error) {
        console.error("Error fetching processes or departments:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchProcessesAndDepartments();
  }, [formData.org_id]); // Trigger this when org_id changes

  const GetTaskFlowing = (d) => {
    const selectedProcess = FlowTask.find(
      (process) => process.id === parseInt(d)
    );
    if (selectedProcess) {
      setTasksField(selectedProcess.tasks);
      if (selectedProcess?.tasks?.length === 0) {
        setTaskNumberFlow(1);
      } else {
        const lastObject =
          selectedProcess?.tasks[selectedProcess?.tasks?.length - 1];
        const gettheFlowOrder = lastObject.task_flow_order;
        const AddCountFlowOrder = gettheFlowOrder + 1;

        setTaskNumberFlow(parseInt(AddCountFlowOrder));
      }
    } else {
      setTasksField([]);
      setTaskNumberFlow(1);
    }
  };
  console.log(roleOptions, "jkll");

  // Handle input changes
  const handleChange = (e) => {
    const { name, type, checked, value } = e.target;

    // Update the process ID and fetch related tasks when a process is selected
    if (name === "process_id") {
      setProcessGetvalue(value);
      GetTaskFlowing(parseInt(value, 10));
    }

    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));

    // Fetch roles when a department is selected
    if (name === "dept_id") {
      const selectedDepartmentId = parseInt(value, 10);
      const selectedDepartment = departmentOptions.find(
        (dept) => dept.value === selectedDepartmentId
      );

      if (selectedDepartment) {
        setRoleOptions(selectedDepartment.roles);

        // Automatically set dept_role_id to the first role or clear if no roles
        const newRoleId =
          selectedDepartment.roles.length > 0
            ? selectedDepartment.roles[0].value
            : ""; // Use an empty string for the value if no roles are found
        setFormData((prevData) => ({
          ...prevData,
          dept_role_id: newRoleId,
        }));
      } else {
        setRoleOptions([]);
        setFormData((prevData) => ({
          ...prevData,
          dept_role_id: "", // Clear dept_role_id if no department is selected
        }));
      }
    }
  };

  useEffect(() => {
    fetchOrganizations();
  }, []);

  const fetchOrganizations = useCallback(async () => {
    try {
      const response = await URL.get("api/allorg");
      const organizationData = response.data.data;
      console.log("organizationData", organizationData);
      setOrganizations(organizationData);
    } catch (error) {
      console.error("Error fetching organizations:", error);
    }
  }, []);

  return (
    <Container className="task-form-container">
      <h2 className="text-center mb-4">Task</h2>
      <Form>
        <Row>
          {/* Organization Name Dropdown */}
          <Col md={6} style={{ padding: "10px" }}>
            <Form.Group controlId="org_id">
              <Form.Label>
                Organization
                <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                as="select"
                name="org_id"
                value={formData.org_id}
                onChange={handleChange}
                className={`select-placeholder ${
                  formData.organization === "" ? "placeholder" : ""
                }`}>
                <option value="" style={{ color: "#525658" }}>
                  Select Organization
                </option>
                {organizations.map((org) => (
                  <option key={org.id} value={org.id}>
                    {org.org_name}
                  </option>
                ))}
              </Form.Control>
              {errors.org_id && (
                <small className="text-danger">{errors.org_id}</small>
              )}{" "}
              {/* Error Message */}
            </Form.Group>
          </Col>

          {/* Process Name Dropdown */}
          <Col md={6} style={{ padding: "10px" }}>
            <Form.Group controlId="process_id">
              <Form.Label>
                Process Name
                <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                as="select"
                name="process_id"
                value={formData.process_id}
                onChange={handleChange}
                disabled={loading}>
                <option value="">Select Process</option>
                {processOptions.map((process) => (
                  <option key={process.key} value={process.value}>
                    {process.label}
                  </option>
                ))}
              </Form.Control>
              {/* {loading && <small>Loading processes...</small>} */}
              {errors.process_id && (
                <small className="text-danger">{errors.process_id}</small>
              )}
            </Form.Group>
          </Col>

          {/* Department Dropdown */}
          <Col md={6} style={{ padding: "10px" }}>
            <Form.Group controlId="dept_id">
              <Form.Label>
                Department
                <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                as="select"
                name="dept_id"
                value={formData.dept_id}
                onChange={handleChange}>
                <option value="">Select Department</option>
                {departmentOptions.map((dept) => (
                  <option key={dept.key} value={dept.value}>
                    {dept.label}
                  </option>
                ))}
              </Form.Control>
              {errors.dept_id && (
                <small className="text-danger">{errors.dept_id}</small>
              )}
            </Form.Group>
          </Col>

          {/* Department Role Dropdown */}
          <Col md={6} style={{ padding: "10px" }}>
            <Form.Group controlId="dept_role_id">
              <Form.Label>
                Department Role
                <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                as="select"
                name="dept_role_id"
                value={formData.dept_role_id}
                onChange={handleChange}
                disabled={roleOptions.length === 0}>
                <option value="">Select Department Role</option>
                {roleOptions.map((role, index) => (
                  <option key={`${role.value}-${index}`} value={role.value}>
                    {role.label}
                  </option>
                ))}
              </Form.Control>
              {errors.dept_role_id && (
                <small className="text-danger">{errors.dept_role_id}</small>
              )}
            </Form.Group>
          </Col>

          {/* Task Name */}
          <Col md={6} style={{ padding: "10px" }}>
            <Form.Group controlId="task_name">
              <Form.Label>
                Task Name
                <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                name="task_name"
                value={formData.task_name}
                placeholder="Task Name"
                onChange={handleChange}
              />
            </Form.Group>
            {errors.task_name && (
              <small className="text-danger">{errors.task_name}</small>
            )}
          </Col>
          {/* User Dropdown */}

          {/* SLA Resolution Time */}
          <Col md={6} style={{ padding: "10px" }}>
            <Form.Group controlId="sla_resolution_time">
              <Form.Label>
                SLA Resolution Time
                <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="time"
                name="sla_resolution_time"
                value={formData.sla_resolution_time}
                onChange={handleChange}
              />
              {errors.sla_resolution_time && (
                <small className="text-danger">
                  {errors.sla_resolution_time}
                </small>
              )}
            </Form.Group>
          </Col>
          {/* Task Flow Order */}
          <Col md={6} style={{ padding: "10px" }}>
            {Editform === true ? (
              <Form.Group controlId="task_flow_order">
                <Form.Label>Task Flow Order</Form.Label>
                <Form.Control
                  disabled
                  type="number"
                  name="task_flow_order"
                  value={editTaskFlowOrder}
                  onChange={handleChange}
                />
              </Form.Group>
            ) : (
              <Form.Group controlId="task_flow_order">
                <Form.Label>Task Flow Order</Form.Label>
                <Form.Control
                  disabled
                  type="number"
                  name="task_flow_order"
                  value={TaskNumberFlow}
                  onChange={handleChange}
                />
              </Form.Group>
            )}
          </Col>
          {/* Is Document Required Checkbox */}
          <Col md={6} style={{ padding: "50px 10px" }}>
            <Form.Group controlId="is_doc_upload_required">
              <Form.Check
                type="checkbox"
                label="Is Document Required"
                name="is_doc_upload_required"
                checked={formData.is_doc_upload_required}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
          {/* Task Description */}
          <Col md={12} style={{ padding: "10px" }}>
            <Form.Group controlId="task_description">
              <Form.Label>Task Description (if any)</Form.Label>
              <Form.Control
                as="textarea"
                name="task_description"
                rows={4}
                value={formData.task_description}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
        </Row>

        {/* Action Buttons */}
        <div className="form-actions mb-4">
          <Button
            variant="outline-light"
            type="reset"
            className="cancel-btn"
            onClick={handleClose}>
            Cancel Task
          </Button>
          <Button
            variant="outline-light"
            // type="submit"
            className="create-btn"
            onClick={() => handleSubmit(TaskNumberFlow, formData)}>
            {buttonName}
          </Button>
        </div>
      </Form>
    </Container>
  );
};

export default TaskForm;
