import React from "react";
import { Toast, ToastContainer } from "react-bootstrap";

export default function BootstrapToast({
  open,
  onSetOpen,
  children,
  duration = 2000,
  variant = "success",
}) {
  // Auto-hide logic
  React.useEffect(() => {
    if (open) {
      const timer = setTimeout(() => {
        onSetOpen(false);
      }, duration);
      return () => clearTimeout(timer);
    }
  }, [open, duration, onSetOpen]);

  return (
    <ToastContainer position="top-end" className="p-3">
      <Toast onClose={() => onSetOpen(false)} show={open} bg={variant}>
        <Toast.Header>
          <strong className="me-auto">Notification</strong>
        </Toast.Header>
        <Toast.Body>{children}</Toast.Body>
      </Toast>
    </ToastContainer>
  );
}
