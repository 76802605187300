import React from "react";

const Select = (props) => {
  const {
    initialValue,
    name,
    id,
    label,
    width,
    options,
    placeholder,
    classes,
    error,
    ...rest
  } = props;

  return (
    <div
      className={classes ? `form-group ${classes}` : "form-group"}
      style={{ width: width && width }}
    >
      <label htmlFor={name} className="form-label">
        {label} {props.required && <span className="text-danger">*</span>}
      </label>
      <select
        className={error ? "form-control is-invalid" : "form-control"}
        id={name}
        name={name}
        {...rest}
      >
        {placeholder && (
          <option value={initialValue ? "All" : ""}>
            {initialValue ? "All" : placeholder}
          </option>
        )}

        {options.map((option) => (
          <option key={option.id} value={option.id}>
            {option.name}
          </option>
        ))}
      </select>
      <div className="invalid-feedback">{error}</div>
    </div>
  );
};

export default Select;
