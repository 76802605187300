import React from "react";
import Sidebar from "./SidebarComponents/Sidebar";
import Navbar from "./NavComponents/Navbar";
import { Outlet } from "react-router-dom";
import "../App.css";
import { useDispatch, useSelector } from "react-redux";
import { selectIsSidebarOpen } from "../redux/SideBarSlice";



const Layout = () => {
  const dispatch = useDispatch();
  const isOpen = useSelector(selectIsSidebarOpen);

  return (
    <div className="layout_container">
      <div className={isOpen?"sidebar_layout":"sidebar_collpse_layout"}>
        <Sidebar />
      </div>
      <div
        className=" main_container"  >
        <Navbar />
        <main className="body_container">
          <Outlet />
        </main>
      </div>
    </div>
  );
};

export default Layout;
