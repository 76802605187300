import { createBrowserRouter, Navigate } from "react-router-dom";

import Dashboard from "./Pages/Dashboard";
import ProtectedRoute from "./components/ProtectedRoute";
import Task from "./Pages/Task";
import Organization from "./Pages/Organization";
import Process from "./Pages/Process";
import MasterOrganization from "./Pages/Organization/MasterOrganization";
import Login from "./Pages/Login/Login";
import AddOrganizationStaff from "./Pages/Organization/AddOrganizationStaff";
import NotFound from "./Pages/NotFound";
import EditOrganizationStaff from "./Pages/Organization/EditOrganizationStaff";
import Tree from "./components/TreeComponent/Tree/Tree";

const isAuthendicated = () => {
  return true;
};

const router = createBrowserRouter([
  {
    path: "/",
    element: <Login />,
  },

  {
    path: "/",
    element: <ProtectedRoute isAuthendicated={isAuthendicated} />,
    children: [
      {
        path: "/dashboard",
        element: <Dashboard />,
      },
      {
        path: "/master/org",
        element: <Organization />,
      },
      {
        path: "/organization-staff-add",
        element: <AddOrganizationStaff />,
      },
      {
        path: "/organization-staff-edit/:id",
        element: <EditOrganizationStaff />,
      },
      {
        path: "/master/task",
        element: <Task />,
      },
      {
        path: "/master/organization",
        element: <MasterOrganization />,
      },
      {
        path: "/master/process",
        element: <Process />,
      },
      {
        path: "/*",
        element: <NotFound />,
      },
      {
        path: "/master/tree",
        element: <Tree />,
      },
    ],
  },
]);

export default router;
