import React from "react";
import { Row, Col } from "react-bootstrap";
import { Ticket } from "../components/DashComponents/Ticket";
import {
  FcAddDatabase,
  FcList,
  FcProcess,
  FcCheckmark,
  FcExpired,
} from "react-icons/fc";
import Charts from "../components/DashComponents/Data/Chart";
import {
  Chart as ChartJS,
  ArcElement,
  BarElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend,
} from "chart.js";

// Register necessary Chart.js components
ChartJS.register(
  ArcElement,
  BarElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend
);

// TicketData
const ticketData = [
  {
    name: "New Tickets",
    count: 2,
    icon: <FcAddDatabase />,
    background:
      "linear-gradient(to right, rgb(250 202 207), rgb(255 204 188 / 18%))",
    Graphbackground: ["rgb(250, 202, 207)", "rgb(250, 202, 207)"],
  },
  {
    name: "Unassigned Tickets",
    count: 17,
    icon: <FcList />,
    background: "linear-gradient(to right, #bbdefb, #e3f2fd)",
    Graphbackground: ["rgb(187, 222, 251)", "rgb(187, 222, 251)"],
  },
  {
    name: "Inprogress Tickets",
    count: 10,
    icon: <FcProcess />,
    background:
      "linear-gradient(to right, rgb(247 212 253), rgb(225 190 231 / 30%))",
    Graphbackground: ["rgb(247, 212, 253)", "rgb(247, 212, 253)"],
  },
  {
    name: "Closed Tickets",
    count: 9,
    icon: <FcCheckmark />,
    background:
      "linear-gradient(to right, rgb(178, 251, 194), rgb(178 251 194 / 29%))",
    Graphbackground: ["rgb(178, 251, 194)", "rgb(178, 251, 194)"],
  },

  {
    name: "Pending Approvals",
    count: 10,
    icon: <FcExpired />,
    background:
      "linear-gradient(to right, rgb(255, 227, 171), rgb(255 244 222 / 64%))",
    Graphbackground: ["rgb(255, 227, 171)", "rgb(255, 227, 171)"],
  },
];

const Dashboard = () => {
  //createGradient
  const createGradient = (ctx, colors) => {
    const gradient = ctx.createLinearGradient(0, 0, 0, 400);

    colors.forEach((color, index) => {
      const rgbaMatch = color.match(
        /rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d?\.?\d*))?\)/
      );
      if (rgbaMatch) {
        const [_, r, g, b, a] = rgbaMatch;
        gradient.addColorStop(
          index / (colors.length - 1),
          `rgba(${r}, ${g}, ${b}, ${a ? a : 1})` // Default alpha to 1 if not defined
        );
      } else {
        console.warn(`Invalid color format: ${color}`);
      }
    });

    return gradient;
  };

  // chart data
  const chartData = {
    labels: ticketData.map((ticket) => ticket.name),
    datasets: [
      {
        label: "Tickets",
        data: ticketData.map((ticket) => ticket.count),
        backgroundColor: ticketData.map((ticket) => {
          const canvas = document.createElement("canvas");
          const ctx = canvas.getContext("2d");
          return createGradient(ctx, ticket.Graphbackground);
        }),
      },
    ],
  };

  return (
    <div>
      <Row >
        {/* <div  className="dboard_ticket_count_container"> */}
        {/* {ticketData.map((ticket,i) => (
          <div   key={i}>
            <Ticket
              count={ticket.count}
              ticket_name={ticket.name}
              icon={ticket.icon}
              background={ticket.background}
            />
          </div>
        ))} */}
        {/* </div> */}
        <Col lg={2} md={4} sm={6} xs={12} > <Ticket ticket_name={<>New <br/> Tickets </>} count="15" icon={<FcAddDatabase />} background="linear-gradient(to right, rgb(250 202 207), rgb(255 204 188 / 18%))" Graphbackground=""  /> </Col>
        <Col lg={2} md={4} sm={6} xs={12} > <Ticket ticket_name={<>Unassigned <br/> Tickets </>} count="20" icon={<FcList />} background="linear-gradient(to right, #bbdefb, #e3f2fd)" Graphbackground=""  /> </Col>
        <Col lg={2} md={4} sm={6} xs={12} > <Ticket ticket_name={<>Inprogress <br/> Tickets </>} count="11" icon={<FcProcess />} background="linear-gradient(to right, rgb(247 212 253), rgb(225 190 231 / 30%))" Graphbackground=""  /> </Col>
        <Col lg={2} md={4} sm={6} xs={12} > <Ticket ticket_name={<>Closed <br/> Tickets </>} count="56" icon={<FcCheckmark />} background="linear-gradient(to right, rgb(178, 251, 194), rgb(178 251 194 / 29%))" Graphbackground=""  /> </Col>
        <Col lg={2} md={4} sm={6} xs={12} > <Ticket ticket_name={<>Pending  <br/>Approvals </>} count="0" icon={<FcExpired />} background="linear-gradient(to right, rgb(255, 227, 171), rgb(255 244 222 / 64%))" Graphbackground=""  /> </Col>
        <Col lg={2} md={4} sm={6} xs={12} > <Ticket ticket_name={<>Delayed <br/> Tickets </>} count="2" icon={<FcProcess />} background="linear-gradient(to right, rgb(247 212 253), rgb(225 190 231 / 30%))" Graphbackground=""  /> </Col>

      </Row>
      <Row>
        {/* Tabular Section */}
        <Col lg={6} md={6} className="mb-3">
          {/* Your tabular content can go here */}
        </Col>

        {/* Charts Section */}
        <Col  lg={6}  md={6} sm={12} className="p-0 m-0">
          <Charts chartData={chartData} />
        </Col>
      </Row>
    </div>
  );
};

export default Dashboard;
