import React, { useState } from "react";
import ProcessForm from "./ProcessForm";
import URL from "../../../API/Api";
import Swal from "sweetalert2";

const AddProcess = ({ processNumberFlow, handleClose }) => {
  const [formData, setFormData] = useState({
    processName: "",
    orgName: "",
    department: "",
    processFlowOrder: "",
  });
  const [errors, setErrors] = useState({});
  const [departments, setDepartments] = useState([]);
  // Validation function
  const validateForm = () => {
    const newErrors = {};
    if (!formData.processName)
      newErrors.processName = "Process Name is required.";
    if (!formData.orgName) newErrors.orgName = "Organization Name is required.";
    if (!formData.department) newErrors.department = "Department is required.";
    return newErrors;
  };
  //submit
  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    const processData = {
      process_name: formData.processName,
      org_id: formData.orgName,
      process_flow_order: formData.process_flow_order,
      dept_id: formData.department,
    };

    try {
      const response = await URL.post("api/process", processData);
      console.log("Process Data Successfully Submitted:", response.data);

      // Show success toaster
      Swal.fire({
        icon: "success",
        title: "Success",
        text: response.data.message || "Process Data Successfully Submitted",
        position: "top-right",
        toast: true,
        timer: 3000,
        background: "rgb(255, 165, 0)",
        color: "#fff",
        timerProgressBar: true,
        showConfirmButton: false,
      });

      // Clear form data
      setFormData({
        processName: "",
        department: "",
        processFlowOrder: "",
      });

      // Optionally call handleClose to hide the form after successful submission
      handleClose();
    } catch (error) {
      console.error("Error submitting process data:", error);

      // Display API validation errors in the form
      if (error.response && error.response.data) {
        const apiError = error.response.data;
        const errorMessage =
          apiError.errors || "Error submitting process data. Please try again.";

        // Set errors to display in form
        setErrors({
          ...errors,
          processName: apiError.errors || errorMessage, // assuming 'processName' error here; adjust if needed
        });

        // Optionally show error toaster
        Swal.fire({
          icon: "error",
          title: "Error",
          text: errorMessage,
          position: "top-right",
          toast: true,
          timer: 3000,
          background: "#dc3545",
          color: "#fff",
          timerProgressBar: true,
          showConfirmButton: false,
        });
      }
    }
  };

  return (
    <div>
      <ProcessForm
        processNumberFlow={processNumberFlow}
        formData={formData}
        setFormData={setFormData}
        handleSubmit={handleSubmit}
        buttonName="Add Process"
        handleClose={handleClose}
        setDepartments={setDepartments}
        errors={errors}
      />
    </div>
  );
};

export default AddProcess;
