import React from "react";
import "./DashComponents.css";

export const Ticket = ({ count, ticket_name, icon, background }) => {
  return (
    <div
      className="ticket  d-flex flex-column "
      style={{ background }}>
      <h5 className=" py-4" style={{ paddingLeft:'27px' }}>{ticket_name}</h5>
      <div className="ticket_info">
        <div className="count_disc">{count}</div>
        <div className="ticket_icon">{icon}</div>
      </div>
    </div>
  );
};
